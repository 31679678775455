import axios from "axios";
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import { Button, Container, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Jumbotron, TutorFooter } from "../navigator";
import { RoleName } from "../passport";
import { accountService, CircleTransfer, materialService, UserTransfer } from "../service";
import { AppStore, PassportActionType, SystemActionType, SystemNotifyAction } from "../store";
import { CircleDelete } from "./circledelete";
import { CircleEdit } from "./circleedit";

const CircleList: FunctionComponent = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const passport = useSelector((store: AppStore) => store.passport);
    const abort = useRef<AbortController>();

    const [circles, setCircles] = useState<CircleTransfer[]>();
    const [tutors, setTutors] = useState<UserTransfer[]>();
    const [edit, setEdit] = useState<boolean>(false);
    const [remove, setRemove] = useState<number>();

    const getTutors = useCallback(async () => {
        try {
            abort.current = new AbortController();
            const response = await accountService().get(`/tenant/${passport.tenant}/user`, {
                signal: abort.current.signal,
                params: {
                    role: RoleName.TUTOR,
                }
            });

            switch (response.status) {
                case 200:
                    setTutors(response.data as UserTransfer[]);
                    break;
                case 401:
                    console.warn("Invalid or expired token, try to login again");

                    dispatch({
                        type: PassportActionType.LOGOUT,
                    });

                    history.push("/login");
                    break;
                default:
                    dispatch<SystemNotifyAction>({
                        type: SystemActionType.NOTIFY,
                        toast: true,
                        title: "🛸",
                        subtitle: new Date().toLocaleTimeString(),
                        content: `错误信息：${response.status} ${response.statusText}，请联系管理员。`,
                    });
                    break;
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                // this should be fine
            } else {
                console.log("Error found in account service request", error);
            }
        }
    }, [dispatch, history, passport.tenant]);

    const getCircles = useCallback(async () => {
        try {
            abort.current = new AbortController();
            const response = await materialService().get(`/tenant/${passport.tenant}/circle`, {
                signal: abort.current.signal,
            });

            switch (response.status) {
                case 200:
                    // collect users information
                    const info = response.data as CircleTransfer[];
                    setCircles(info);

                    break;
                case 401:
                    console.warn("Invalid or expired token, try to login again");

                    dispatch({
                        type: PassportActionType.LOGOUT,
                    });

                    history.push("/login");
                    break;
                default:
                    dispatch<SystemNotifyAction>({
                        type: SystemActionType.NOTIFY,
                        toast: true,
                        title: "🛸",
                        subtitle: new Date().toLocaleTimeString(),
                        content: `错误信息：${response.status} ${response.statusText}，请联系管理员。`,
                    });
                    break;
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                // this should be fine
            } else {
                console.log("Error found in account service request", error);
            }
        }
    }, [passport.tenant, dispatch, history]);

    useEffect(() => {
        getTutors();
        getCircles();
    }, [getTutors, getCircles]);

    useEffect(() => {
        return () => {
            abort.current?.abort();
        }
    }, []);

    return (
        <>
            <Jumbotron>
                <Container>
                    <h1>班级</h1>
                </Container>
            </Jumbotron>
            <Container>
                <Button
                    variant="primary"
                    onClick={() => setEdit(true)}
                    style={{ textDecoration: "none" }}
                >
                    新建
                </Button>

                <CircleEdit
                    show={edit}
                    id={0}
                    tutors={tutors || []}
                    onExit={() => { setEdit(false); getCircles(); }}
                />

                <CircleDelete
                    id={remove}
                    onExit={() => getCircles()}
                />

                <Table bordered hover size="sm" className="my-4">
                    <thead>
                        <tr>
                            <th>编号</th>
                            <th>名称</th>
                            <th>注释</th>
                            <th>导师</th>
                            <th>操作</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            circles?.map(circle =>
                                <tr key={circle.id}>
                                    <td>
                                        {circle.id}
                                    </td>
                                    <td>
                                        <Link to={`/tenant/${passport.tenant}/circle/${circle.id}`} style={{ textDecoration: "none" }}>
                                            {circle.name}
                                        </Link>
                                    </td>
                                    <td>
                                        {circle.comment}
                                    </td>
                                    <td>
                                        {tutors?.find(tutor => tutor.username === circle.tutor)?.fullname}
                                    </td>
                                    <td>
                                        <Button
                                            size="sm"
                                            variant="outline-secondary"
                                            style={{ textDecoration: "none" }}
                                            className="py-0 mx-2"
                                            onClick={() => setRemove(circle.id)}
                                        >
                                            移除
                                        </Button>
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>

                <TutorFooter />
            </Container>
        </>
    );
}

export { CircleList };
