import React, { FunctionComponent } from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { EnrollmentList } from "../enrollment/enrollmentlist";
import { LessonList } from "../lesson";
import { Jumbotron, TutorFooter } from "../navigator";
import { CircleBasic } from "./circlebasic";

interface RouteParams {
    id: string
}

const CircleDetail: FunctionComponent = () => {
    const { id } = useParams<RouteParams>();

    return (
        <>
            <Jumbotron>
                <Container>
                    <h1>班级</h1>
                </Container>
            </Jumbotron>
            <Container>
                <Tabs defaultActiveKey="basic" className="mb-4">
                    <Tab eventKey="basic" title="信息">
                        <CircleBasic id={parseInt(id)} />
                    </Tab>

                    <Tab eventKey="enrollment" title="学员" tabClassName={id === "0" ? "d-none" : ""}>
                        <EnrollmentList id={parseInt(id)} />
                    </Tab>

                    <Tab eventKey="list" title="排课" tabClassName={id === "0" ? "d-none" : ""}>
                        <LessonList circle={parseInt(id)} />
                    </Tab>
                </Tabs>

                <TutorFooter />
            </Container>
        </>
    );
}

export { CircleDetail };
