import axios from "axios";
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import { Button, Card, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { accountService, SettingTransfer } from "../service";
import { SystemActionType, SystemNotifyAction } from "../store";
import { difficulties, StudentDifficulty } from "./studentdifficulty";

interface RouteParams {
    username: string
}

/**
 * Panel for the tutor to config setting for the students.
 *
 * Tutors can modify the setting for better
 */
const StudentSetting: FunctionComponent = () => {
    const { username } = useParams<RouteParams>();
    const dispatch = useDispatch();

    const [show, setShow] = useState<boolean>(false);
    const [setting, setSetting] = useState<SettingTransfer>();
    const abort = useRef<AbortController>();

    const getSetting = useCallback(async () => {
        try {
            abort.current = new AbortController();
            const response = await accountService().get(`/setting/${username}`, {
                signal: abort.current.signal,
            });

            switch (response.status) {
                case 200:
                    const setting = response.data as SettingTransfer;
                    setSetting(setting);
                    break;
                default:
                    dispatch<SystemNotifyAction>({
                        type: SystemActionType.NOTIFY,
                        toast: true,
                        title: "🛸",
                        subtitle: new Date().toLocaleTimeString(),
                        content: `错误信息：${response.status} ${response.statusText}，请联系管理员。`,
                    });
                    break;
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                // this should be fine
            } else {
                console.log("Error found in account service request", error);
            }
        }
    }, [username, dispatch]);

    useEffect(() => {
        getSetting();
    }, [getSetting]);

    useEffect(() => {
        return () => {
            abort.current?.abort();
        }
    }, []);

    return (
        <Card>
            <Card.Body>
                <Table borderless>
                    <thead>
                        <tr>
                            <th>学习</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr >
                            <td>难度</td>
                            <td>
                                {difficulties.find(item => item.level === setting?.difficulty)?.name}
                            </td>
                            <td>简易模式可以复制答案；普通模式不可复制答案；困难模式完成前不能参考答案。</td>
                            <td onClick={() => setShow(true)}>
                                <Button size="sm" variant="outline" onClick={() => setShow(true)}>
                                    <span role="img" aria-label="edit">✏️</span>
                                </Button>
                                {
                                    setting &&
                                    <StudentDifficulty
                                        show={show}
                                        username={username}
                                        setting={setting}
                                        onExit={() => getSetting()}
                                    />
                                }
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    );
}

export { StudentSetting };
