import React, { FunctionComponent } from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import { Jumbotron, TutorFooter } from "../navigator";
import { UserBasic } from "../user/userbasic";
import { TutorExtra } from "./tutorextra";

export const TutorDetail: FunctionComponent = () => {
    return (
        <>
            <Jumbotron>
                <Container>
                    <h1>导师</h1>
                </Container>
            </Jumbotron>
            <Container>
                <Tabs defaultActiveKey="basic" className="mb-4">
                    <Tab eventKey="basic" title="信息">
                        <UserBasic />
                    </Tab>

                    <Tab eventKey="extra" title="描述">
                        <TutorExtra />
                    </Tab>
                </Tabs>

                <TutorFooter />
            </Container>
        </>
    );
}