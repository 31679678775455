import { ResponsivePie } from "@nivo/pie";
import axios, { AxiosInstance } from "axios";
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import { Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { HealthTransfer } from "../service";
import { SystemActionType, SystemNotifyAction } from "../store";

interface Params {
    name: string;
    service: () => AxiosInstance;
}

const ServiceStatus: FunctionComponent<Params> = ({ name, service }) => {
    const dispatch = useDispatch();

    const [online, setOnline] = useState<boolean>(false);
    const abort = useRef<AbortController>();

    const getStatus = useCallback(async () => {
        try {
            abort.current = new AbortController();
            const response = await service().get(`/actuator/health`, {
                signal: abort.current.signal,
            });

            switch (response.status) {
                case 200:
                    setOnline((response.data as HealthTransfer).status === "UP");
                    break;
                default:
                    dispatch<SystemNotifyAction>({
                        type: SystemActionType.NOTIFY,
                        toast: true,
                        title: "🛸",
                        subtitle: new Date().toLocaleTimeString(),
                        content: `错误信息：${response.status} ${response.statusText}，请联系管理员。`,
                    });
                    break;
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                // this should be fine
            } else {
                console.log("Error found in account service request", error);
            }
        }
    }, [dispatch, service]);

    useEffect(() => {
        getStatus();
    }, [getStatus]);

    useEffect(() => {
        return () => {
            abort.current?.abort();
        }
    }, []);

    return (
        <>
            <Col>
                <ResponsivePie
                    data={[
                        {
                            id: `${name} 在线率`,
                            value: online ? 100 : 30,
                            color: "#61cdbb",
                        },
                        {
                            id: `${name} 离线率`,
                            value: online ? 0 : 70,
                            color: "#f47560",
                        }
                    ]}
                    margin={{ top: 60, right: 10, bottom: 60, left: 10 }}
                    innerRadius={0.5}
                    activeOuterRadiusOffset={8}
                    startAngle={-90}
                    endAngle={90}
                    colors={item => item.data.color}
                />
            </Col>
        </>
    )
}

export { ServiceStatus };
