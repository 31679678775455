import React, { ChangeEvent, FormEvent, FunctionComponent, useEffect, useState } from "react";
import { Button, Col, Form, FormControl, InputGroup, Modal, Row, Spinner } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { accountService, UserTransfer } from "../service";
import { AppStore, SystemActionType, SystemNotifyAction } from "../store";

interface Params {
    show: boolean;
    id: number;
    onExit: () => void;
}

export const StudentCreate: FunctionComponent<Params> = (params) => {
    const passport = useSelector((store: AppStore) => store.passport);
    const dispatch = useDispatch();

    const [spin, setSpin] = useState(false);
    const [show, setShow] = useState<boolean>(false);
    const [username, setUsername] = useState<string>();
    const [candidate, setCandidate] = useState<UserTransfer>();

    useEffect(() => {
        setShow(params.show);
    }, [params.show]);

    const initialize = () => {
        setUsername(undefined);
        setCandidate(undefined);
    };

    const finalize = () => {
        params.onExit();
    }

    const recruit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        setSpin(true);

        const response = await accountService().post(`/tenant/${passport.tenant}/user`, {
            id: 0,
            username: username,
            roles: [],
        });

        switch (response.status) {
            case 200:
                break;
            default:
                dispatch<SystemNotifyAction>({
                    type: SystemActionType.NOTIFY,
                    toast: true,
                    title: "🛸",
                    subtitle: new Date().toLocaleTimeString(),
                    content: `错误信息：${response.status} ${response.statusText}，请联系管理员。`,
                });
                break;
        }

        setShow(false);
        setSpin(false);
    }

    const search = async () => {
        const response = await accountService().get(`/user/${username}`);

        switch (response.status) {
            case 200:
                const candidate = response.data as UserTransfer;
                if (candidate.roles.includes("student")) {
                    setCandidate(candidate);
                }
                break;
            case 404:
                // candidate not found
                setCandidate(undefined);
                break;
            default:
                dispatch<SystemNotifyAction>({
                    type: SystemActionType.NOTIFY,
                    toast: true,
                    title: "🛸",
                    subtitle: new Date().toLocaleTimeString(),
                    content: `错误信息：${response.status} ${response.statusText}，请联系管理员。`,
                });
                break;
        }
    }

    const change = (event: ChangeEvent<HTMLInputElement>) => {
        setUsername(event.target.value.trim());
    };

    return (
        <Modal show={show ? true : false} onEnter={initialize} onExited={finalize}>
            <Modal.Header>
                <Modal.Title>
                    学生
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form onSubmit={recruit} id="form">
                    <Row>
                        <Form.Group as={Col} controlId="id">
                            <InputGroup>
                                <FormControl
                                    type="text"
                                    placeholder="用户名"
                                    onChange={change}
                                />
                                <Button variant="link" disabled={!username} onClick={search} className="ms-2">
                                    <BsSearch></BsSearch>
                                </Button>
                            </InputGroup>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} controlId="id">
                            {
                                candidate &&
                                <>
                                    <p className="mt-3">用户 <strong>{candidate.fullname}</strong> 已找到 😀</p>
                                </>
                            }
                        </Form.Group>
                    </Row>
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="primary" type="submit" form="form" disabled={!candidate}>
                    {
                        spin && <Spinner as="span" size="sm" animation="border" variant="light" className="me-2" />
                    }
                    确认
                </Button>

                <Button variant="outline-secondary" onClick={() => setShow(false)}>
                    取消
                </Button>
            </Modal.Footer>
        </Modal>
    );
}