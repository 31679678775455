import React, { FunctionComponent } from "react";

export const Jumbotron: FunctionComponent = (params) => {
    return (
        <div
            className="fluid mb-5"
            style={{
                padding: "4em",
                backgroundColor: "#E9ECEF",
                backgroundImage: `url("https://resource.codemage.cn/background-artificial-intelligence.svg")`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
            }}
        >
            {params.children}
        </div>
    );
}