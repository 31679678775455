import React, { FunctionComponent } from "react";
import { Container } from "react-bootstrap";
import { Jumbotron } from "../navigator";
import { ServicePanel } from "./servicepanel";

export const DashboardComponent: FunctionComponent = () => {
    return (
        <>
            <Jumbotron>
                <Container>
                    <h1>仪表盘</h1>
                </Container>
            </Jumbotron>

            <ServicePanel />
        </>
    )
}