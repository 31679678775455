import React, { FormEvent, FunctionComponent } from "react";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import { SubmissionTransfer } from "../service";

interface GradeParams {
    submission: SubmissionTransfer;
    grade(submission: SubmissionTransfer, value: number): void;
}

interface Grade {
    name: string;
    label: string;
    value: number;
}

export const SubmissionGrade: FunctionComponent<GradeParams> = (params) => {
    const options: Grade[] = [
        {
            name: "😐",
            label: "中性",
            value: 0,
        },
        {
            name: "🙂",
            label: "开心",
            value: 1,
        },
        {
            name: "😀",
            label: "高兴",
            value: 2,
        },
        {
            name: "🙁",
            label: "伤心",
            value: 3,
        },
    ];

    const change = async (event: FormEvent<HTMLInputElement>) => {
        event.preventDefault();

        const value = parseInt(event.currentTarget.value);

        params.grade(params.submission, value);
    }

    return (
        <ButtonGroup>
            {
                options.map((option, idx) =>
                    <ToggleButton
                        key={idx}
                        id={`${params.submission.id}-${idx}`}
                        type="radio"
                        variant="outline-light"
                        name="radio"
                        value={option.value}
                        checked={option.value === (params.submission?.grade || 0)}
                        onChange={change}
                    >
                        {option.name}
                    </ToggleButton>
                )
            }
        </ButtonGroup>
    )
}