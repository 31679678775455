import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, RouteComponentProps, RouteProps } from "react-router-dom";
import { AppStore } from "../store";

interface PrivateProps extends RouteProps {
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

interface PublicProps extends RouteProps {
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
    restricted?: boolean;
}

export const PrivateRoute: FunctionComponent<PrivateProps> = ({
    component: Component,
    ...rest
}) => {
    const passport = useSelector((store: AppStore) => store.passport);

    return (
        <Route
            {...rest}
            render={props => (
                passport.authenticated ?
                    <Component {...props} />
                    :
                    <Redirect
                        to={{
                            pathname: "/login",
                        }}
                    />
            )}
        />
    );
};

export const PublicRoute: FunctionComponent<PublicProps> = ({
    component: Component,
    restricted,
    ...rest
}) => {
    const passport = useSelector((store: AppStore) => store.passport);

    return (
        <Route
            {...rest}
            render={props => (
                passport.authenticated && restricted ?
                    <Redirect
                        to={{
                            pathname: "/"
                        }}
                    />
                    :
                    <Component {...props} />
            )}
        />
    );
};