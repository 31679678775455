import axios from "axios";
import moment from "moment-timezone";
import React, { ChangeEvent, FormEvent, FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useDispatch, useSelector } from "react-redux";
import { ChapterTransfer, LessonTransfer, materialService } from "../service";
import { AppStore, SystemActionType, SystemNotifyAction } from "../store";

interface Params {
    show: boolean;
    id: number,
    circle?: number,
    lesson?: LessonTransfer,
    onExit: () => void,
}

const LessonEdit: FunctionComponent<Params> = (params) => {
    const passport = useSelector((store: AppStore) => store.passport);
    const dispatch = useDispatch();
    const abort = useRef<AbortController>();

    const [validated, setValidated] = useState(false);
    const [show, setShow] = useState<boolean>(false);
    const [spin, setSpin] = useState(false);
    const [chapters, setChapters] = useState<ChapterTransfer[]>();
    const [lesson, setLesson] = useState<Partial<LessonTransfer>>();

    const getChapters = useCallback(async () => {
        try {
            abort.current = new AbortController();
            const response = await materialService().get(`/chapter`, {
                signal: abort.current.signal,
            });

            switch (response.status) {
                case 200:
                    setChapters(response.data as ChapterTransfer[]);
                    break;
                default:
                    dispatch<SystemNotifyAction>({
                        type: SystemActionType.NOTIFY,
                        toast: true,
                        title: "🛸",
                        subtitle: new Date().toLocaleTimeString(),
                        content: `错误信息：${response.status} ${response.statusText}，请联系管理员。`,
                    });
                    break;
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                // this should be fine
            } else {
                console.log("Error found in account service request", error);
            }
        }
    }, [dispatch]);

    useEffect(() => {
        getChapters();
    }, [getChapters]);

    useEffect(() => {
        setShow(params.show);
    }, [params.show]);

    useEffect(() => {
        return () => {
            abort.current?.abort();
        }
    }, []);

    const initialize = () => {
        setLesson({
            ...params.lesson,
            id: params.id,
            circle: params.lesson?.circle || params.circle,
            time: params.lesson?.time || moment().format("YYYY-MM-DDTHH:mm"),
            zone: moment.tz.guess(),
        });

        setValidated(false);
    };

    const finalize = () => {
        setLesson({});

        params.onExit();
    }

    const change = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setLesson({
            ...lesson,
            [name]: value.trim(),
        });
    };

    const select = (event: ChangeEvent<HTMLSelectElement>) => {
        const { name, options, selectedIndex } = event.target;

        setLesson({
            ...lesson,
            [name]: options[selectedIndex].value,
        });
    };

    const choose = (selected: ChapterTransfer[]) => {
        setLesson({
            ...lesson,
            chapter: selected.length === 0 ? undefined : {
                id: selected[0].id,
                title: "",
                summary: "",
                sections: []
            },
        });
    }

    const submit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (event.currentTarget.checkValidity()) {
            setSpin(true);

            const response = params.id === 0 ?
                await materialService().post(`/tenant/${passport.tenant}/lesson`, lesson)
                :
                await materialService().put(`/tenant/${passport.tenant}/lesson/${params.id}`, lesson);

            switch (response.status) {
                case 200:
                    break;
                default:
                    dispatch<SystemNotifyAction>({
                        type: SystemActionType.NOTIFY,
                        toast: true,
                        title: "🛸",
                        subtitle: new Date().toLocaleTimeString(),
                        content: `错误信息：${response.status} ${response.statusText}，请联系管理员。`,
                    });
                    break;
            }

            setShow(false);
            setSpin(false);
        } else {
            setValidated(true);
        }
    };

    return (
        <Modal show={show ? true : false} size="lg" onEnter={initialize} onExited={finalize}>
            <Modal.Header>
                <Modal.Title>班级</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form noValidate validated={validated} onSubmit={submit} id="form">
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="chapter">
                            <Form.Label>章节</Form.Label>
                            <Typeahead
                                id="circle-chapter-typeahead"
                                labelKey={(chapter) => `${chapter.course?.title} / ${chapter.title}`}
                                onChange={selected => choose(selected)}
                                options={chapters || []}
                                inputProps={{ required: true }}
                                selected={chapters?.filter(chapter => chapter.id === lesson?.chapter?.id) || []}
                                placeholder="选择要讲授的章节"
                            />
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="datetime">
                            <Form.Label>时间</Form.Label>
                            <Form.Control
                                type="datetime-local"
                                name="time"
                                value={lesson?.time || moment().format("YYYY-MM-DDTHH:mm")}
                                onChange={change}
                            />
                        </Form.Group>

                        <Form.Group as={Col} controlId="duration">
                            <Form.Label>时长</Form.Label>
                            <Form.Select
                                required
                                name="duration"
                                title="请选择时长"
                                value={lesson?.duration}
                                onChange={select}
                            >
                                <option label=""></option>
                                <option value={45} key={45}>45分钟</option>
                                <option value={60} key={60}>60分钟</option>
                                <option value={90} key={90}>90分钟</option>
                            </Form.Select>
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="webinar">
                            <Form.Label>网课</Form.Label>
                            <Form.Control
                                type="text"
                                name="webinar"
                                value={lesson?.webinar || ""}
                                onChange={change}
                            />
                        </Form.Group>
                    </Row>

                    <Row>
                        <Form.Group as={Col} controlId="record">
                            <Form.Label>录像</Form.Label>
                            <Form.Control
                                type="text"
                                name="record"
                                value={lesson?.record || ""}
                                onChange={change}
                            />
                        </Form.Group>
                    </Row>
                </Form>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" type="submit" form="form" className="mt-4" disabled={spin}>
                    {
                        spin && <Spinner as="span" size="sm" animation="border" variant="light" className="me-2" />
                    }
                    保存
                </Button>

                <Button variant="link" onClick={() => setShow(false)} className="mt-4 ms-2" style={{ textDecoration: "none" }}>
                    取消
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export { LessonEdit };
