import axios from "axios";
import moment from "moment-timezone";
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { LessonTransfer, materialService } from "../service";
import { AppStore, SystemActionType, SystemNotifyAction } from "../store";
import { LessonDelete } from "./lessondelete";
import { LessonEdit } from "./lessonedit";

interface Params {
    circle: number;
}

interface RemoveParams {
    id: number;
    name: string;
}

const LessonList: FunctionComponent<Params> = ({ circle }) => {
    const history = useHistory();
    const passport = useSelector((store: AppStore) => store.passport);
    const dispatch = useDispatch();
    const abort = useRef<AbortController>();

    const [lessons, setLessons] = useState<LessonTransfer[]>();
    const [create, setCreate] = useState<boolean>(false);
    const [remove, setRemove] = useState<RemoveParams>();

    const getLessons = useCallback(async () => {
        try {
            abort.current = new AbortController();
            const response = await materialService().get(`/tenant/${passport.tenant}/lesson`, {
                signal: abort.current.signal,
                params: {
                    circle: circle,
                }
            });

            switch (response.status) {
                case 200:
                    setLessons(response.data as LessonTransfer[]);
                    break;
                default:
                    dispatch<SystemNotifyAction>({
                        type: SystemActionType.NOTIFY,
                        toast: true,
                        title: "🛸",
                        subtitle: new Date().toLocaleTimeString(),
                        content: `错误信息：${response.status} ${response.statusText}，请联系管理员。`,
                    });
                    break;
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                // this should be fine
            } else {
                console.log("Error found in account service request", error);
            }
        }
    }, [circle, passport.tenant, dispatch])

    useEffect(() => {
        getLessons();
    }, [getLessons]);

    useEffect(() => {
        return () => {
            abort.current?.abort();
        }
    }, []);

    return (
        <>
            <Button
                variant="primary"
                onClick={() => setCreate(true)}
            >
                新建
            </Button>

            <LessonEdit
                show={create}
                id={0}
                circle={circle}
                onExit={() => { setCreate(false); getLessons(); }}
            />

            <LessonDelete
                id={remove?.id}
                name={remove?.name}
                onExit={() => { setRemove(undefined); getLessons(); }}
            />

            <Table bordered hover size="sm" className="mt-4 mb-4">
                <thead>
                    <tr>
                        <th>编号</th>
                        <th>课程</th>
                        <th>时间</th>
                        <th>时长</th>
                        <th>操作</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        lessons?.map(lesson =>
                            <tr key={lesson.id}>
                                <td >
                                    {lesson.id}
                                </td>
                                <td>
                                    <Link to={`/tenant/${passport.tenant}/lesson/${lesson.id}`} style={{ textDecoration: "none" }} >
                                        {lesson.name} ({lesson.chapter?.id})
                                    </Link>
                                </td>
                                <td>
                                    {moment.tz(lesson.time, lesson.zone).format("YYYY-MM-DD HH:mm")}
                                </td>
                                <td>
                                    {lesson.duration}
                                </td>
                                <td>
                                    <Button
                                        size="sm"
                                        variant="outline-secondary"
                                        className="py-0 mx-1"
                                        onClick={() => history.push(`/tenant/${passport.tenant}/review/${lesson.id}`)}
                                    >
                                        评审
                                    </Button>

                                    <Button
                                        size="sm"
                                        variant="outline-secondary"
                                        className="py-0 mx-1"
                                        onClick={() => window.open(`https://learn.codemage.cn/lesson/${lesson.id}`, "_blank")}
                                    >
                                        访问
                                    </Button>

                                    <Button
                                        size="sm"
                                        variant="outline-secondary"
                                        className="py-0 mx-1"
                                        onClick={() => setRemove({ id: lesson.id, name: lesson.name })}
                                    >
                                        移除
                                    </Button>
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </>
    );
}

export { LessonList };
