import { TokenFormat } from "../passport";
import { PassportAction, PassportActionType, PassportStore } from "./passportactions";
import jwt_decode from "jwt-decode";

const initial: PassportStore = {
    authenticated: false,
    username: "游客",
    roles: [],
    tenants: [],
    tenant: "",
    token: undefined,
};

export function passportReducer(state = initial, action: PassportAction): PassportStore {
    switch (action.type) {
        case PassportActionType.LOGIN:
            localStorage.setItem("access-token", action.token!);
            localStorage.setItem("username", action.username);

            const token = jwt_decode<TokenFormat>(action.token || "");
            const roles = token.roles.split(",");
            const tenants = token.tenants.split(",");
            const tenant = tenants.length > 0 ? tenants[0] : "";

            return {
                ...state,
                authenticated: true,
                username: action.username,
                roles: roles,
                tenants: tenants,
                tenant: tenant,
                token: action.token,
            };
        case PassportActionType.LOGOUT:
            localStorage.removeItem("access-token");
            localStorage.removeItem("username");

            return {
                ...state,
                authenticated: false,
                username: "游客",
                tenant: "",
                roles: [],
                token: undefined,
            };
        default:
            return state;
    }
}
