import React, { ChangeEvent, FormEvent, FunctionComponent, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { CircleTransfer, materialService, UserTransfer } from "../service";
import { AppStore, SystemActionType, SystemNotifyAction } from "../store";

interface Params {
    id: number,
    show: boolean;
    circle?: CircleTransfer,
    tutors: UserTransfer[],
    onExit: () => void,
}

export const CircleEdit: FunctionComponent<Params> = (params) => {
    const passport = useSelector((store: AppStore) => store.passport);
    const dispatch = useDispatch();

    const [validated, setValidated] = useState(false);
    const [spin, setSpin] = useState(false);
    const [show, setShow] = useState<boolean>(params.show);
    const [circle, setCircle] = useState<Partial<CircleTransfer>>({
        ...params.circle,
        id: params.id,
    });

    useEffect(() => {
        setShow(params.show);
    }, [params.show]);

    const change = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setCircle({
            ...circle,
            [name]: value.trim(),
        });
    };

    const select = (event: ChangeEvent<HTMLSelectElement>) => {
        const { name, options, selectedIndex } = event.target;

        setCircle({
            ...circle,
            [name]: options[selectedIndex].value,
        });
    };

    const submit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (event.currentTarget.checkValidity()) {
            setSpin(true);

            const response = params.id === 0 ?
                await materialService().post(`/tenant/${passport.tenant}/circle`, circle)
                :
                await materialService().put(`/tenant/${passport.tenant}/circle/${params.id}`, circle);

            setSpin(false);
            setShow(false);

            switch (response.status) {
                case 200:
                    break;
                default:
                    dispatch<SystemNotifyAction>({
                        type: SystemActionType.NOTIFY,
                        toast: true,
                        title: "🛸",
                        subtitle: new Date().toLocaleTimeString(),
                        content: `错误信息：${response.status} ${response.statusText}，请联系管理员。`,
                    });
                    break;
            }
        } else {
            setValidated(true);
        }
    };

    return (
        <Modal show={show} size="lg" onExit={() => params.onExit()}>
            <Modal.Header>
                <Modal.Title>班级</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form noValidate validated={validated} onSubmit={submit} id="form">
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="id">
                            <Form.Label>编号</Form.Label>
                            <Form.Control
                                type="text"
                                readOnly
                                value={circle?.id || ""}
                            />
                        </Form.Group>

                        <Form.Group as={Col} controlId="tenant">
                            <Form.Label>机构</Form.Label>
                            <Form.Control
                                type="text"
                                readOnly
                                value={circle?.tenant || ""}
                            />
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="name">
                            <Form.Label>名字</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                name="name"
                                pattern=".{2,30}"
                                title="课程名要在2至30个字符之间"
                                value={circle?.name || ""}
                                onChange={change}
                            />
                        </Form.Group>

                        <Form.Group as={Col} controlId="comment">
                            <Form.Label>注释</Form.Label>
                            <Form.Control
                                type="text"
                                name="comment"
                                value={circle?.comment || ""}
                                onChange={change}
                            />
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="tutor">
                            <Form.Label>导师</Form.Label>
                            <Form.Select
                                required
                                name="tutor"
                                title="请选择导师"
                                value={circle?.tutor}
                                onChange={select}
                            >
                                <option label=""></option>
                                {
                                    params.tutors?.map(tutor =>
                                        <option value={tutor.username} key={tutor.username}>
                                            {tutor.fullname}
                                        </option>
                                    )
                                }
                            </Form.Select>
                        </Form.Group>

                        <Form.Group as={Col} controlId="thumbnail">
                            <Form.Label>图示</Form.Label>
                            <Form.Select
                                required
                                name="thumbnail"
                                value={circle?.thumbnail}
                                onChange={select}
                            >
                                <option label=""></option>
                                <option value="https://resource.codemage.cn/poster-computational-thinking.svg" key={1}>
                                    计算思维原理篇
                                </option>
                                <option value="https://resource.codemage.cn/poster-math.svg" key={2}>
                                    计算思维数学篇
                                </option>
                                <option value="https://resource.codemage.cn/poster-usaco.svg" key={3}>
                                    美国信奥青铜级
                                </option>
                            </Form.Select>
                        </Form.Group>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" type="submit" form="form" className="mt-4" disabled={spin}>
                    {
                        spin && <Spinner as="span" size="sm" animation="border" variant="light" className="me-2" />
                    }
                    保存
                </Button>

                <Button variant="link" onClick={() => setShow(false)} className="mt-4 ms-2" style={{ textDecoration: "none" }}>
                    取消
                </Button>
            </Modal.Footer>
        </Modal>
    );
}