import React, { FunctionComponent } from "react";
import { Container, Row } from "react-bootstrap";
import { accountService, materialService, notebookService } from "../service";
import { ServiceStatus } from "./servicestatus";

export const ServicePanel: FunctionComponent = () => {
    return (
        <>
            <Container >
                <Row style={{ height: "300px" }}>
                    <ServiceStatus name="身份服务" service={accountService} />
                    <ServiceStatus name="内容服务" service={materialService} />
                    <ServiceStatus name="手册服务" service={notebookService} />
                </Row>
            </Container>
        </>
    )
}
