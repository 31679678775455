import React, { FunctionComponent } from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import { Jumbotron } from "../navigator";
import { UserBasic } from "../user";
import { StudentSetting } from "./studentsetting";

export const StudentDetail: FunctionComponent = () => {
    return (
        <>
            <Jumbotron>
                <Container>
                    <h1>学生</h1>
                </Container>
            </Jumbotron>
            <Container>
                <Tabs defaultActiveKey="basic" className="mb-4">
                    <Tab eventKey="basic" title="信息">
                        <UserBasic />
                    </Tab>

                    <Tab eventKey="setting" title="设定">
                        <StudentSetting />
                    </Tab>
                </Tabs>
            </Container>
        </>
    );
}