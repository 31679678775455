import axios from "axios";

export const accountService = () => {
    const token = localStorage.getItem("access-token");

    return axios.create({
        baseURL: process.env.NODE_ENV === "production" ? "https://account.codemage.cn" : "http://localhost:3030",
        validateStatus: (status: number) => (status >= 200 && status < 300) || [401, 403, 404, 409, 500].includes(status),
        headers: {
            "Authorization": `Bearer ${token}`,
        },
    });
}

export const notebookService = () => {
    const token = localStorage.getItem("access-token");

    const service = axios.create({
        baseURL: process.env.NODE_ENV === "production" ? "https://notebook.codemage.cn/" : "http://localhost:8080/",
        validateStatus: (status: number) => (status >= 200 && status < 300) || [400, 401, 403, 404, 500].includes(status),
        headers: {
            "Authorization": `Bearer ${token}`,
        },
    });

    return service;
}

export const materialService = () => {
    const token = localStorage.getItem("access-token");

    const service = axios.create({
        baseURL: process.env.NODE_ENV === "production" ? "https://material.codemage.cn/" : "http://localhost:6060/",
        validateStatus: (status: number) => (status >= 200 && status < 300) || [400, 401, 403, 404, 500].includes(status),
        headers: {
            "Authorization": `Bearer ${token}`,
        },
    });

    return service;
}