import axios from "axios";
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import { Button, Card, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RoleName } from "../passport";
import { accountService, CircleTransfer, materialService, UserTransfer } from "../service";
import { AppStore, SystemActionType, SystemNotifyAction } from "../store";
import { CircleEdit } from "./circleedit";

interface Params {
    id: number
}

const CircleBasic: FunctionComponent<Params> = ({ id }) => {
    const passport = useSelector((store: AppStore) => store.passport);
    const dispatch = useDispatch();
    const abort = useRef<AbortController>();
    const cancel = useRef<AbortController>();

    const [tutors, setTutors] = useState<UserTransfer[]>();
    const [circle, setCircle] = useState<CircleTransfer>();
    const [edit, setEdit] = useState<boolean>(false);

    const getCircle = useCallback(async () => {
        try {
            abort.current = new AbortController();
            const response = await materialService().get(`/tenant/${passport.tenant}/circle/${id}`, {
                signal: abort.current.signal,
            });

            switch (response.status) {
                case 200:
                    setCircle(response.data as CircleTransfer);
                    break;
                default:
                    dispatch<SystemNotifyAction>({
                        type: SystemActionType.NOTIFY,
                        toast: true,
                        title: "🛸",
                        subtitle: new Date().toLocaleTimeString(),
                        content: `错误信息：${response.status} ${response.statusText}，请联系管理员。`,
                    });
                    break;
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                // this should be fine
            } else {
                console.log("Error found in account service request", error);
            }
        }
    }, [id, passport.tenant, dispatch]);

    const getTutors = useCallback(async () => {
        try {
            cancel.current = new AbortController();
            const response = await accountService().get(`/tenant/${passport.tenant}/user`, {
                signal: cancel.current.signal,
                params: {
                    role: RoleName.TUTOR,
                }
            });

            switch (response.status) {
                case 200:
                    const result = response.data as UserTransfer[];
                    setTutors(result);
                    break;
                default:
                    dispatch<SystemNotifyAction>({
                        type: SystemActionType.NOTIFY,
                        toast: true,
                        title: "🛸",
                        subtitle: new Date().toLocaleTimeString(),
                        content: `错误信息：${response.status} ${response.statusText}，请联系管理员。`,
                    });
                    break;
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                // this should be fine
            } else {
                console.log("Error found in account service request", error);
            }
        }
    }, [passport.tenant, dispatch]);

    useEffect(() => {
        getCircle();
        getTutors();
    }, [getCircle, getTutors]);

    useEffect(() => {
        return () => {
            abort.current?.abort();
            cancel.current?.abort();
        }
    }, []);

    return (
        <>
            <Card className="mb-4">
                <Card.Body>
                    <Table borderless size="sm">
                        <thead>
                            <tr>
                                <th style={{ width: "25%" }}>班级信息</th>
                                <th style={{ width: "25%" }}></th>
                                <th style={{ width: "25%" }}></th>
                                <th style={{ width: "25%", textAlign: "right" }}>
                                    <Button variant="outline" onClick={() => setEdit(true)}>
                                        <span role="img" aria-label="edit">✏️</span>
                                    </Button>

                                    {
                                        circle && tutors &&
                                        <CircleEdit
                                            show={edit}
                                            id={id}
                                            circle={circle}
                                            tutors={tutors}
                                            onExit={() => { setEdit(false); getCircle(); }}
                                        />
                                    }
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>编号</td>
                                <td>
                                    {circle?.id}
                                </td>
                                <td>标识班级的编号</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>机构</td>
                                <td>
                                    {circle?.tenant}
                                </td>
                                <td>开设班级的机构代号</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>名称</td>
                                <td>
                                    {circle?.name}
                                </td>
                                <td>班级具体名称</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>注释</td>
                                <td>
                                    {circle?.comment}
                                </td>
                                <td>班级附加注释</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>导师</td>
                                <td>
                                    {tutors?.find(tutor => tutor.username === circle?.tutor)?.fullname}
                                </td>
                                <td>负责班级的导师</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </>
    );
}

export { CircleBasic };
