import { SystemAction, SystemActionType, SystemStore } from "./systemactions";

const initial: SystemStore = {
    toast: false,
    title: "",
    subtitle: "",
    achieved: false,
    content: "",
};

export function systemReducer(state = initial, action: SystemAction): SystemStore {
    switch (action.type) {
        case SystemActionType.NOTIFY:
            return {
                ...state,
                toast: action.toast,
                title: action.title,
                subtitle: action.subtitle,
                content: action.content,
            };

        case SystemActionType.ACKNOWLEDGE:
            return {
                ...state,
                toast: false,
            };

        default:
            return state;
    }
}
