import React, { FunctionComponent } from "react";
import { BrowserRouter } from "react-router-dom";
import { Navigator } from "./navigator";
import { ContentRouter, Notification } from "./router";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/eclipse.css";
import "codemirror/mode/python/python";
import "./app.css";

export const App: FunctionComponent = () => {
    return (
        <div>
            <BrowserRouter>
                <Navigator />
                <Notification />
                <ContentRouter />
            </BrowserRouter>
        </div>
    );
}