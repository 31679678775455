import React, { FunctionComponent } from "react";
import { Container, Toast } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { AppStore, SystemActionType } from "../store";

export const Notification: FunctionComponent = () => {
    const system = useSelector((store: AppStore) => store.system);
    const dispatch = useDispatch();

    return (
        <Container>
            <div className="m-2" style={{ position: "absolute", left: "50%", transform: "translate(-50%, -50%)" }}>
                <Toast show={system.toast} onClose={() => dispatch({ type: SystemActionType.ACKNOWLEDGE })} autohide delay={5000}>
                    <Toast.Header>
                        <strong className="me-auto">{system.title}</strong>
                        <small>{system.subtitle}</small>
                    </Toast.Header>
                    <Toast.Body className="bg-warning">
                        {system.content}
                    </Toast.Body>
                </Toast>
            </div>
        </Container>
    );
}