import React, { ChangeEvent, FormEvent, FunctionComponent, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { materialService, TutorTransfer } from "../service";
import { AppStore, SystemActionType, SystemNotifyAction } from "../store";

interface Params {
    show: boolean,
    tutor: TutorTransfer,
    onExit: () => void,
}

export const TutorBio: FunctionComponent<Params> = (params) => {
    const passport = useSelector((store: AppStore) => store.passport);
    const dispatch = useDispatch();

    const [tutor, setTutor] = useState<Partial<TutorTransfer>>({
        bio: params.tutor.bio,
    });
    const [spin, setSpin] = useState(false);
    const [show, setShow] = useState<boolean>(false);
    const [validated, setValidated] = useState(false);

    useEffect(() => {
        setShow(params.show);
    }, [params.show]);

    const initialize = () => {
    };

    const finalize = () => {
        setValidated(false);

        params.onExit();
    }

    const change = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setTutor({
            ...tutor,
            [name]: value.trim(),
        });
    };

    const update = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (event.currentTarget.checkValidity()) {
            setSpin(true);

            const response = await materialService().put(`/tenant/${passport.tenant}/tutor/${params.tutor.username}`, tutor);

            setSpin(false);
            setShow(false);

            switch (response.status) {
                case 200:
                    break;
                default:
                    dispatch<SystemNotifyAction>({
                        type: SystemActionType.NOTIFY,
                        toast: true,
                        title: "🛸",
                        subtitle: new Date().toLocaleTimeString(),
                        content: `错误信息：${response.status} ${response.statusText}，请联系管理员。`,
                    });
                    break;
            }
        } else {
            setValidated(true);
        }
    };

    return (
        <Modal show={show} size="lg" onEnter={initialize} onExited={finalize}>
            <Modal.Header>
                <Modal.Title>简历</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validated} onSubmit={update} id="form">
                    <Row>
                        <Form.Group as={Col} controlId="bio">
                            <Form.Control
                                as="textarea"
                                name="bio"
                                rows={5}
                                value={tutor.bio || ""}
                                placeholder="二百字以内个人经历"
                                required
                                maxLength={200}
                                onChange={change}
                            />
                            <Form.Control.Feedback type="invalid">
                                二百字以内
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="primary" type="submit" form="form" disabled={spin}>
                    {
                        spin && <Spinner as="span" size="sm" animation="border" variant="light" className="me-2" />
                    }
                    确认
                </Button>

                <Button variant="outline-secondary" onClick={() => setShow(false)}>
                    取消
                </Button>
            </Modal.Footer>
        </Modal>
    );
}