export enum SystemActionType {
    NOTIFY = "SYSTEM_NOTIFY",
    ACKNOWLEDGE = "SYSTEM_ACKNOWLEDGE",
}

export interface SystemNotifyAction {
    type: typeof SystemActionType.NOTIFY;
    toast: boolean;
    title: string;
    subtitle: string;
    content: string;
}

export interface SystemAcknowledgeAction {
    type: typeof SystemActionType.ACKNOWLEDGE;
}

export type SystemAction = SystemNotifyAction | SystemAcknowledgeAction;

export interface SystemStore {
    readonly toast: boolean;
    readonly title: string;
    readonly subtitle: string;
    readonly achieved: boolean;
    readonly content: string;
}