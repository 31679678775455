import axios from "axios";
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import { Button, Container, OverlayTrigger, Pagination, Table, Tooltip } from "react-bootstrap";
import { VscLock } from "react-icons/vsc";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Jumbotron, TutorFooter } from "../navigator";
import { accountService, UserTransfer } from "../service";
import { AppStore, PassportActionType, SystemActionType, SystemNotifyAction } from "../store";
import { UserRole } from "../user";
import { StudentCreate } from "./studentcreate";
import { StudentDelete } from "./studentdelete";

interface UserListInfo {
    content: UserTransfer[];
    totalPages: number;
    totalElements: number;
    number: number;
    size: number;
}

const StudentList: FunctionComponent = () => {
    const history = useHistory();
    const passport = useSelector((store: AppStore) => store.passport);
    const dispatch = useDispatch();

    const abort = useRef<AbortController>();

    const [students, setStudents] = useState<UserListInfo>();
    const [page, setPage] = useState<number>(0);
    const [pages, setPages] = useState<number[]>();
    const [create, setCreate] = useState<boolean>(false);
    const [remove, setRemove] = useState<string>();

    const getStudents = useCallback(async () => {
        try {
            abort.current = new AbortController();

            const response = await accountService().get(`/tenant/${passport.tenant}/user`, {
                signal: abort.current.signal,
                params: {
                    role: "student",
                    page: `${page}`,
                    size: 20,
                }
            });

            switch (response.status) {
                case 200:
                    // collect users information
                    const info = response.data as UserListInfo;
                    setStudents(info);

                    // collect series for pagination
                    var series = [];
                    for (var i = 1; i <= info.totalPages; i++) {
                        series.push(i);
                    }
                    setPages(series);

                    break;
                case 401:
                    console.warn("Invalid or expired token, try to login again");

                    dispatch({
                        type: PassportActionType.LOGOUT,
                    });

                    history.push("/login");
                    break;
                default:
                    dispatch<SystemNotifyAction>({
                        type: SystemActionType.NOTIFY,
                        toast: true,
                        title: "🛸",
                        subtitle: new Date().toLocaleTimeString(),
                        content: `错误信息：${response.status} ${response.statusText}，请联系管理员。`,
                    });
                    break;
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                // this should be fine
            } else {
                console.log("Error found in account service request", error);
            }
        }
    }, [page, dispatch, history, passport.tenant]);

    useEffect(() => {
        getStudents();

        return () => {
            abort.current?.abort();
        }
    }, [getStudents]);

    const navigate = (value: number) => {
        setPage(value - 1);
    }

    return (
        <>
            <Jumbotron>
                <Container>
                    <h1>学生</h1>
                </Container>
            </Jumbotron>
            <Container>
                <Button
                    variant="primary"
                    onClick={() => setCreate(true)}
                >
                    添加
                </Button>

                <StudentCreate
                    show={create}
                    id={0}
                    onExit={() => { setCreate(false); getStudents(); }}
                />

                <StudentDelete
                    username={remove}
                    onExit={() => { setRemove(undefined); getStudents(); }}
                />

                <Table bordered hover size="sm" className="mt-4 mb-4">
                    <thead>
                        <tr>
                            <th>用户名</th>
                            <th>全名</th>
                            <th>创建</th>
                            <th>到期</th>
                            <th>权限</th>
                            <th>渠道</th>
                            <th>操作</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            students?.content.map(student =>
                                <tr key={student.id}>
                                    <td>
                                        <Link to={`/tenant/${passport.tenant}/student/${student.username}`} style={{ textDecoration: "none" }}>
                                            {student.username}
                                        </Link>
                                        {
                                            student.active === false && <VscLock className="ms-2" />
                                        }
                                    </td>
                                    <td>{student.fullname}</td>
                                    <td>{student.dateCreated}</td>
                                    <td>
                                        {student.dateExpired}

                                        <OverlayTrigger
                                            placement="right"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="tooltip-overdue">账户已到期</Tooltip>}
                                        >
                                            <span>
                                                {new Date(student.dateExpired!) > new Date() ? "" : " ❌ "}
                                            </span>
                                        </OverlayTrigger>
                                    </td>
                                    <td>
                                        <UserRole roles={student.roles} />
                                    </td>
                                    <td>{student.channel}</td>
                                    <td>
                                        <Button
                                            size="sm"
                                            variant="outline-secondary"
                                            className="pt-0 pb-0 me-2"
                                            onClick={() => setRemove(student.username)}
                                        >
                                            移除
                                        </Button>
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>

                <Pagination >
                    {
                        pages?.map(value =>
                            <Pagination.Item key={value} active={value === page + 1} onClick={() => navigate(value)}>
                                {value}
                            </Pagination.Item>
                        )
                    }
                </Pagination>

                <TutorFooter />
            </Container>
        </>
    );
}

export { StudentList };
