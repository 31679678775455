import { LocationDescriptorObject } from 'history';
import jwt_decode from "jwt-decode";
import React, { ChangeEvent, FormEvent, FunctionComponent, useState } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { JwtResponse, RoleName, TokenFormat } from ".";
import { accountService } from "../service";
import { PassportActionType, PassportLoginAction } from "../store";

interface Props {
    location: LocationDescriptorObject;
};

export const LoginComponent: FunctionComponent<Props> = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [validated, setValidated] = useState(false);
    const [username, setUsername] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [response, setResponse] = useState(200);

    /**
     * Handler for user to submit the code.
     * 
     * @param event form event
    */
    const submit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;

        if (form.checkValidity() === true) {
            const response = await accountService().get("/passport", {
                params: {
                    username: username,
                    password: password
                }
            });

            setResponse(response.status);

            switch (response.status) {
                case 200:
                    console.info("login successfully");

                    const transfer = response.data as JwtResponse;
                    const token = jwt_decode<TokenFormat>(transfer.token || "");
                    const roles = token.roles.split(",");

                    if (roles.includes(RoleName.TUTOR) || roles.includes(RoleName.PRINCIPAL)) {
                        dispatch<PassportLoginAction>({
                            type: PassportActionType.LOGIN,
                            username: transfer.username,
                            token: transfer.token,
                        });

                        history.push("/dashboard");
                    } else {
                        setResponse(403);
                    }

                    break;

                case 401:
                    console.warn("invalid username or password");

                    break;
            }
        } else {
            setValidated(true);
        }
    };

    const hint = () => {
        switch (response) {
            case 401:
                return "错误的用户名或者密码！";
            case 403:
                return "仅供教师或校长使用！"
        }
    }

    const alert = () => {
        if (response !== 200) {
            return (
                <Alert variant="danger" onClose={() => setResponse(200)} dismissible>
                    {
                        hint()
                    }
                </Alert>
            );
        }
    }

    return (
        <>
            <Container>
                <Row className="mt-5">
                    <Col xs={0} md={3}></Col>
                    <Col xs={12} md={6}>
                        {alert()}

                        <Form noValidate validated={validated} onSubmit={submit}>
                            <Form.Control
                                required
                                autoComplete="off"
                                type="text"
                                placeholder="用户名"
                                className="mt-3 mb-3"
                                value={username || ""}
                                pattern=".{2,20}"
                                title="用户名要在2至20个字符之间"
                                onChange={(event: ChangeEvent<HTMLInputElement>) => setUsername(event.target.value)}
                            />

                            <Form.Control
                                required
                                type="password"
                                placeholder="密码"
                                className="mt-3 mb-3"
                                value={password || ""}
                                pattern=".{6,20}"
                                title="密码要在6至20个字符之间"
                                onChange={(event: ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
                            />

                            <Button variant="primary" type="submit">
                                登入
                            </Button>
                        </Form>
                    </Col>
                    <Col xs={0} md={3}></Col>
                </Row>

            </Container>
        </>
    )

}
