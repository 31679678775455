import axios from "axios";
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { accountService, EnrollmentTransfer, materialService, UserTransfer } from "../service";
import { AppStore, SystemActionType, SystemNotifyAction } from "../store";
import { EnrollmentCreate } from "./enrollmentcreate";
import { EnrollmentDelete } from "./enrollmentdelete";

interface Params {
    id: number;
}

const EnrollmentList: FunctionComponent<Params> = ({ id }) => {
    const passport = useSelector((store: AppStore) => store.passport);
    const dispatch = useDispatch();
    const abort = useRef<AbortController>();
    const cancel = useRef<AbortController>();

    const [enrollments, setEnrollments] = useState<EnrollmentTransfer[]>();
    const [students, setStudents] = useState<UserTransfer[]>();
    const [show, setShow] = useState<boolean>(false);
    const [candidate, setCandidate] = useState<string>(); // candidate to remove

    const getEnrollments = useCallback(async () => {
        try {
            abort.current = new AbortController();
            const response = await materialService().get(`/tenant/${passport.tenant}/enrollment`, {
                signal: abort.current.signal,
                params: {
                    circle: id,
                }
            });

            switch (response.status) {
                case 200:
                    setEnrollments(response.data as EnrollmentTransfer[]);
                    break;
                default:
                    dispatch<SystemNotifyAction>({
                        type: SystemActionType.NOTIFY,
                        toast: true,
                        title: "🛸",
                        subtitle: new Date().toLocaleTimeString(),
                        content: `错误信息：${response.status} ${response.statusText}，请联系管理员。`,
                    });
                    break;
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                // this should be fine
            } else {
                console.log("Error found in account service request", error);
            }
        }
    }, [passport.tenant, id, dispatch])

    const getStudents = useCallback(async () => {
        try {
            cancel.current = new AbortController();
            const response = await accountService().get(`/tenant/${passport.tenant}/user`, {
                signal: cancel.current.signal,
                params: {
                    role: "student",
                }
            });

            switch (response.status) {
                case 200:
                    setStudents(response.data as UserTransfer[]);
                    break;
                default:
                    dispatch<SystemNotifyAction>({
                        type: SystemActionType.NOTIFY,
                        toast: true,
                        title: "🛸",
                        subtitle: new Date().toLocaleTimeString(),
                        content: `错误信息：${response.status} ${response.statusText}，请联系管理员。`,
                    });
                    break;
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                // this should be fine
            } else {
                console.log("Error found in account service request", error);
            }
        }
    }, [passport.tenant, dispatch])

    useEffect(() => {
        getStudents();
        getEnrollments();
    }, [getEnrollments, getStudents]);

    useEffect(() => {
        return () => {
            abort.current?.abort();
            cancel.current?.abort();
        }
    }, []);

    const fullname = (username: string) => {
        const result = students?.find(student => {
            return student.username === username;
        });
        return result?.fullname || "";
    }

    return (
        <>
            <Button variant="primary" onClick={() => setShow(true)}>
                添加
            </Button>

            <EnrollmentCreate
                show={show}
                circle={id}
                students={students || []}
                onExit={() => { setShow(false); getEnrollments(); }}
            />

            <EnrollmentDelete
                circle={id}
                username={candidate}
                onExit={() => { setCandidate(undefined); getEnrollments(); }}
            />

            <Table bordered hover size="sm" className="mt-4 mb-4">
                <thead>
                    <tr>
                        <th>账号</th>
                        <th>姓名</th>
                        <th>昵称</th>
                        <th>操作</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        enrollments?.map(enrollment =>
                            <tr key={enrollment.user}>
                                <td>
                                    <Link to={`/tenant/${passport.tenant}/student/${enrollment.user}`} style={{ textDecoration: "none" }} >
                                        {enrollment.user}
                                    </Link>
                                </td>
                                <td>
                                    {`${fullname(enrollment.user)}`}
                                </td>
                                <td>
                                    {enrollment.alias}
                                </td>
                                <td>
                                    <Button
                                        size="sm"
                                        variant="outline-secondary"
                                        className="pt-0 pb-0 mx-1"
                                        onClick={() => setCandidate(enrollment.user)}
                                    >
                                        移除
                                    </Button>
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </>
    );
}

export { EnrollmentList };
