export enum MessageType {
    kernel_info_request = "kernel_info_request",
    kernel_info_reply = "kernel_info_reply",
    execute_request = "execute_request",
    execute_reply = "execute_reply",
    stream = "stream",
    display_data = "display_data",
    update_display_data = "update_display_data",
    execute_input = "execute_input",
    execute_result = "execute_result",
    error = "error",
    status = "status",
    clear_output = "clear_output",
}