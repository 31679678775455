export enum PassportActionType {
    LOGIN = "PASSPORT_LOGIN",
    LOGOUT = "PASSPORT_LOGOUT",
}

export interface PassportLoginAction {
    type: typeof PassportActionType.LOGIN;
    username: string;
    token: string | undefined;
}

export interface PassportLogoutAction {
    type: typeof PassportActionType.LOGOUT;
}

export type PassportAction = PassportLoginAction | PassportLogoutAction;

export interface PassportStore {
    readonly authenticated: boolean;
    readonly username: string;
    readonly roles: Array<string>;
    readonly tenants: string[];
    readonly tenant: string;
    readonly token: string | undefined;
}