import React, { FunctionComponent } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { BsGrid3X3Gap } from "react-icons/bs";
import { useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { AppStore } from "../store";
import { UserComponent } from "./user";

export const Navigator: FunctionComponent = () => {
    const passport = useSelector((store: AppStore) => store.passport);

    const home = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        window.open("https://www.codemage.cn");
    }

    return (
        <Navbar bg="primary" variant="dark" expand="lg">
            <Container>
                <LinkContainer to="https://www.codemage.cn" onClick={home} style={{ cursor: "pointer" }}>
                    <BsGrid3X3Gap size={22} color="white" className="me-2 mt-1" />
                </LinkContainer>

                <LinkContainer to="/">
                    <Navbar.Brand>
                        指导
                    </Navbar.Brand>
                </LinkContainer>

                <Navbar.Toggle aria-controls="mainframe" />

                <Navbar.Collapse id="mainframe">
                    <Nav className="me-auto">
                        <NavDropdown title="班级" id="dropdown-circle">
                            <LinkContainer to={`/tenant/${passport.tenant}/circle`}>
                                <NavDropdown.Item>
                                    浏览
                                </NavDropdown.Item>
                            </LinkContainer>
                        </NavDropdown>

                        <NavDropdown title="学生" id="dropdown-user">
                            <LinkContainer to={`/tenant/${passport.tenant}/student`}>
                                <NavDropdown.Item>
                                    浏览
                                </NavDropdown.Item>
                            </LinkContainer>
                        </NavDropdown>

                        <NavDropdown title="导师" id="dropdown-tutor">
                            <LinkContainer to={`/tenant/${passport.tenant}/tutor`}>
                                <NavDropdown.Item>
                                    浏览
                                </NavDropdown.Item>
                            </LinkContainer>
                        </NavDropdown>
                    </Nav>
                    <Nav>
                        <UserComponent />
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}