export * from "./login";

export interface JwtResponse {
    username: string;
    token: string;
}

export interface TokenFormat {
    exp: number;
    iat: number;
    roles: string;
    tenants: string;
}

export class RoleName {
    public static readonly STUDENT = "student";
    public static readonly ADMIN = "admin";
    public static readonly TUTOR = "tutor";
    public static readonly CODER = "coder";
    public static readonly PRINCIPAL = "principal";
}