import React, { FunctionComponent } from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Jumbotron, TutorFooter } from "../navigator";
import { LessonBasic } from "./lessonbasic";

interface RouteParams {
    id: string;
}

/**
 * A panel to display various information for the lesson. 
 *
 */
export const LessonDetail: FunctionComponent = () => {
    const params = useParams<RouteParams>();

    return (
        <>
            <Jumbotron>
                <Container>
                    <h1>课程</h1>
                </Container>
            </Jumbotron>
            <Container>
                <Tabs defaultActiveKey="basic" className="mb-4">
                    <Tab eventKey="basic" title="信息">
                        <LessonBasic
                            id={parseInt(params.id)}
                        />
                    </Tab>
                </Tabs>

                <TutorFooter />
            </Container>
        </>
    );
}
