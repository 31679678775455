import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { App } from "./app";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { configureStore, PassportActionType } from "./store";

const store = configureStore();

const token = localStorage.getItem("access-token");
const name = localStorage.getItem("username");

if (token) {
    store.dispatch({
        type: PassportActionType.LOGIN,
        username: name,
        token: token,
    });
}

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,

    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
