import axios from "axios";
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Jumbotron, TutorFooter } from "../navigator";
import { EnrollmentTransfer, LessonTransfer, materialService } from "../service";
import { AppStore, SystemActionType, SystemNotifyAction } from "../store";
import { RecipeTable } from "./recipetable";

interface RouteParams {
    id: string,
}

const CodeReview: FunctionComponent = () => {
    const passport = useSelector((store: AppStore) => store.passport);
    const { id } = useParams<RouteParams>();
    const dispatch = useDispatch();
    const abort = useRef<AbortController>();

    const [lesson, setLesson] = useState<LessonTransfer>();
    const [enrollments, setEnrollments] = useState<EnrollmentTransfer[]>();

    const getLesson = useCallback(async () => {
        console.log("Get info for lesson", id);

        try {
            abort.current = new AbortController();
            const response = await materialService().get(`/tenant/${passport.tenant}/lesson/${id}`, {
                signal: abort.current.signal,
            });

            switch (response.status) {
                case 200:
                    setLesson(response.data as LessonTransfer);
                    break;
                default:
                    dispatch<SystemNotifyAction>({
                        type: SystemActionType.NOTIFY,
                        toast: true,
                        title: "🛸",
                        subtitle: new Date().toLocaleTimeString(),
                        content: `错误信息：${response.status} ${response.statusText}，请联系管理员。`,
                    });
                    break;
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                // this should be fine
            } else {
                console.log("Error found in account service request", error);
            }
        }
    }, [id, passport.tenant, dispatch])

    const getEnrollments = useCallback(async () => {
        console.log("Get enrollments for lesson", id);

        try {
            abort.current = new AbortController();
            const response = await materialService().get(`/tenant/${passport.tenant}/enrollment`, {
                signal: abort.current.signal,
                params: {
                    lesson: id
                }
            });

            switch (response.status) {
                case 200:
                    setEnrollments(response.data as EnrollmentTransfer[]);
                    break;
                default:
                    dispatch<SystemNotifyAction>({
                        type: SystemActionType.NOTIFY,
                        toast: true,
                        title: "🛸",
                        subtitle: new Date().toLocaleTimeString(),
                        content: `错误信息：${response.status} ${response.statusText}，请联系管理员。`,
                    });
                    break;
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                // this should be fine
            } else {
                console.log("Error found in account service request", error);
            }
        }
    }, [id, passport.tenant, dispatch])

    useEffect(() => {
        getLesson();
        getEnrollments();
    }, [getLesson, getEnrollments]);

    useEffect(() => {
        return () => {
            abort.current?.abort();
        }
    }, []);

    return (
        <>
            <Jumbotron>
                <Container>
                    <h1>代码评审</h1>
                </Container>
            </Jumbotron>
            <Container>
                <p>
                    班级编号：{lesson?.circle}
                </p>

                <RecipeTable lesson={lesson} enrollments={enrollments} />

                <TutorFooter />
            </Container>
        </>
    )
}

export { CodeReview };
