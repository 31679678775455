import React, { FormEvent, FunctionComponent, useEffect, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useDispatch, useSelector } from "react-redux";
import { materialService, UserTransfer } from "../service";
import { AppStore, SystemActionType, SystemNotifyAction } from "../store";

interface Params {
    circle: number,
    show: boolean,
    students: UserTransfer[],
    onExit: () => void,
}

export const EnrollmentCreate: FunctionComponent<Params> = (params) => {
    const passport = useSelector((store: AppStore) => store.passport);
    const dispatch = useDispatch();

    const [spin, setSpin] = useState(false);
    const [candidate, setCandidate] = useState<string>();
    const [show, setShow] = useState<boolean>(false);

    useEffect(() => {
        setShow(params.show);
    }, [params.show]);

    const initialize = () => {
    };

    const finalize = () => {
        setCandidate(undefined);
        params.onExit();
    }

    const submit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        setSpin(true);

        const response = await materialService().post(`/tenant/${passport.tenant}/enrollment`, null, {
            params: {
                circle: params.circle,
                user: candidate,
            }
        });

        switch (response.status) {
            case 200:
                break;
            default:
                dispatch<SystemNotifyAction>({
                    type: SystemActionType.NOTIFY,
                    toast: true,
                    title: "🛸",
                    subtitle: new Date().toLocaleTimeString(),
                    content: `错误信息：${response.status} ${response.statusText}，请联系管理员。`,
                });
                break;
        }

        setShow(false);
        setSpin(false);
    }

    return (
        <Modal show={show} onEnter={initialize} onExited={finalize}>
            <Modal.Header>
                <Modal.Title>添加</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form onSubmit={submit} id="form">
                    <Typeahead
                        id="usernames-typeahead"
                        labelKey={(user) => `${user.username} / ${user.fullname}`}
                        onChange={selected => setCandidate(selected.length === 0 ? undefined : selected[0].username)}
                        options={params.students || []}
                        selected={params.students?.filter(student => student.username === candidate) || []}
                        placeholder="用户名"
                    />
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="primary" type="submit" form="form" className="mt-4" disabled={spin}>
                    {
                        spin && <Spinner as="span" size="sm" animation="border" variant="light" className="me-2" />
                    }
                    确认
                </Button>

                <Button
                    variant="link"
                    onClick={() => setShow(false)}
                    className="mt-4 ms-2"
                    style={{ textDecoration: "none" }}>
                    取消
                </Button>
            </Modal.Footer>
        </Modal>
    );
}