import React, { FormEvent, FunctionComponent, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { accountService, DifficultyLevel, SettingTransfer } from "../service";
import { SystemActionType, SystemNotifyAction } from "../store";

interface Params {
    show: boolean;
    username: string;
    setting: SettingTransfer;
    onExit: () => void;
}

export const difficulties = [
    { name: "简单", level: DifficultyLevel.EASY },
    { name: "普通", level: DifficultyLevel.NORMAL },
    { name: "困难", level: DifficultyLevel.HARD },
];

export const StudentDifficulty: FunctionComponent<Params> = (params) => {
    const dispatch = useDispatch();

    const [difficulty, setDifficulty] = useState<DifficultyLevel>(params.setting.difficulty);
    const [show, setShow] = useState<boolean>(params.show);
    const [spin, setSpin] = useState(false);

    useEffect(() => {
        setShow(params.show);
    }, [params.show]);

    const submit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        setSpin(true);

        var setting: SettingTransfer = {
            ...params.setting,
            difficulty: difficulty
        };

        const response = await accountService().put(`/setting/${params.username}`, setting);

        switch (response.status) {
            case 200:
                setShow(false);
                break;
            default:
                dispatch<SystemNotifyAction>({
                    type: SystemActionType.NOTIFY,
                    toast: true,
                    title: "🛸",
                    subtitle: new Date().toLocaleTimeString(),
                    content: `错误信息：${response.status} ${response.statusText}，请联系管理员。`,
                });
                break;
        }
        setSpin(false);
    }

    return (
        <>
            <Modal show={show} onEnter={() => setDifficulty(params.setting.difficulty)} onExited={() => params.onExit()}>
                <Modal.Header>
                    <Modal.Title>
                        设置
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form noValidate onSubmit={submit} id="form">
                        <Row>
                            <Form.Group as={Col}>
                                <Form.Label>难度</Form.Label>
                                {
                                    difficulties.map((item, index) =>
                                        <Form.Check
                                            key={index}
                                            id={`difficulty-${index}`}
                                            type="radio"
                                            label={item.name}
                                            value={item.level}
                                            checked={item.level === difficulty}
                                            onChange={(e) => setDifficulty(e.currentTarget.value as DifficultyLevel)}
                                        />
                                    )
                                }
                            </Form.Group>
                        </Row>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" type="submit" form="form" disabled={spin}>
                        {
                            spin && <Spinner as="span" size="sm" animation="border" variant="light" className="me-2" />
                        }
                        确认
                    </Button>

                    <Button variant="outline-secondary" onClick={() => setShow(false)}>
                        取消
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}