import React, { FormEvent, FunctionComponent, useEffect, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { materialService } from "../service";
import { AppStore, SystemActionType, SystemNotifyAction } from "../store";

interface Params {
    id?: number;
    onExit: () => void;
}

export const CircleDelete: FunctionComponent<Params> = (params) => {
    const passport = useSelector((store: AppStore) => store.passport);
    const dispatch = useDispatch();

    const [id, setId] = useState<number>();
    const [spin, setSpin] = useState(false);

    useEffect(() => {
        setId(params.id);
    }, [params.id]);

    const initialize = () => {
    };

    const finalize = () => {
        params.onExit();
    }

    const submit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        setSpin(true);

        const response = await materialService().delete(`/tenant/${passport.tenant}/circle/${params.id}`);

        switch (response.status) {
            case 200:
                break;
            default:
                dispatch<SystemNotifyAction>({
                    type: SystemActionType.NOTIFY,
                    toast: true,
                    title: "🛸",
                    subtitle: new Date().toLocaleTimeString(),
                    content: `错误信息：${response.status} ${response.statusText}，请联系管理员。`,
                });
                break;
        }

        setId(undefined);
        setSpin(false);
    }

    return (
        <Modal show={id ? true : false} onEnter={initialize} onExited={finalize}>
            <Modal.Header closeButton>
                <Modal.Title>
                    提示
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form onSubmit={submit} id="form">
                    即将删除编号为 <strong>{params.id}</strong> 的班级。你确认吗？
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="primary" type="submit" form="form" disabled={spin}>
                    {
                        spin && <Spinner as="span" size="sm" animation="border" variant="light" className="me-2" />
                    }
                    确定
                </Button>

                <Button variant="outline-secondary" onClick={() => setId(undefined)}>
                    取消
                </Button>
            </Modal.Footer>
        </Modal>
    );
}