import axios from "axios";
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import { Card, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { accountService, UserTransfer } from "../service";
import { SystemActionType, SystemNotifyAction } from "../store";

/**
 * A panel to display basic user information for the student and tutor.
 * 
 * These information are readonly they come from the account service, which can be modified by the users or admins only.
 */

interface RouteParams {
    username: string
}

const initial: UserTransfer = {
    username: "",
    roles: [],
};

const UserBasic: FunctionComponent = () => {
    const { username } = useParams<RouteParams>();
    const dispatch = useDispatch();
    const abort = useRef<AbortController>();

    const [tutor, setTutor] = useState<UserTransfer>(initial);

    const getTutor = useCallback(async () => {
        try {
            abort.current = new AbortController();
            const response = await accountService().get(`/user/${username}`, {
                signal: abort.current.signal,
            });

            switch (response.status) {
                case 200:
                    setTutor(response.data as UserTransfer);
                    break;
                default:
                    dispatch<SystemNotifyAction>({
                        type: SystemActionType.NOTIFY,
                        toast: true,
                        title: "🛸",
                        subtitle: new Date().toLocaleTimeString(),
                        content: `错误信息：${response.status} ${response.statusText}，请联系管理员。`,
                    });
                    break;
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                // this should be fine
            } else {
                console.log("Error found in account service request", error);
            }
        }
    }, [username, dispatch]);

    useEffect(() => {
        getTutor();
    }, [getTutor]);

    useEffect(() => {
        return () => {
            abort.current?.abort();
        }
    }, []);

    return (
        <Card className="mb-4">
            <Card.Body>
                <Table borderless size="sm">
                    <thead>
                        <tr>
                            <th style={{ width: "25%" }}>个人信息</th>
                            <th style={{ width: "25%" }}></th>
                            <th style={{ width: "25%" }}></th>
                            <th style={{ width: "25%" }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>账号</td>
                            <td>
                                {tutor.username}
                            </td>
                            <td>不可更改的唯一的标识符</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>全名</td>
                            <td>
                                {tutor.fullname}
                            </td>
                            <td>可以修改的用于显示的称谓</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>状态</td>
                            <td>
                                {tutor.active === false ? "停用" : "正常"}
                            </td>
                            <td>是否可以正常访问网站</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>创建</td>
                            <td>
                                {tutor.dateCreated}
                            </td>
                            <td>账户创建的日期</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>到期</td>
                            <td>
                                {tutor.dateExpired}
                                <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip id="tooltip-overdue">账户已到期</Tooltip>}
                                >
                                    <span>
                                        {new Date(tutor.dateExpired!) > new Date() ? " ✔️ " : " ❌ "}
                                    </span>
                                </OverlayTrigger>
                            </td>
                            <td>账户到期之后将无法执行代码</td>
                            <td></td>
                        </tr>
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    );
}

export { UserBasic };
