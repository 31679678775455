import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { Switch } from "react-router-dom";
import { CircleDetail, CircleList } from "../circle";
import { DashboardComponent } from "../dashboard";
import { LessonDetail } from "../lesson";
import { LoginComponent } from "../passport";
import { CodeReview } from "../review";
import { AppStore } from "../store";
import { StudentDetail, StudentList } from "../student";
import { TutorDetail, TutorList } from "../tutor";
import { PrivateRoute, PublicRoute } from "./statehouse";

export const ContentRouter: FunctionComponent = () => {
    const passport = useSelector((store: AppStore) => store.passport);

    return (
        <>
            <Switch>
                <PublicRoute path="/login" component={LoginComponent} exact restricted />
                <PrivateRoute path="/" component={DashboardComponent} exact />
                <PrivateRoute path="/dashboard" component={DashboardComponent} exact />
                <PrivateRoute path={`/tenant/${passport.tenant}/review/:id`} component={CodeReview} exact />
                <PrivateRoute path={`/tenant/${passport.tenant}/circle`} component={CircleList} exact />
                <PrivateRoute path={`/tenant/${passport.tenant}/circle/:id`} component={CircleDetail} exact />
                <PrivateRoute path={`/tenant/${passport.tenant}/lesson/:id`} component={LessonDetail} exact />
                <PrivateRoute path={`/tenant/${passport.tenant}/tutor`} component={TutorList} exact />
                <PrivateRoute path={`/tenant/${passport.tenant}/tutor/:username`} component={TutorDetail} exact />
                <PrivateRoute path={`/tenant/${passport.tenant}/student`} component={StudentList} exact />
                <PrivateRoute path={`/tenant/${passport.tenant}/student/:username`} component={StudentDetail} exact />
            </Switch>
        </>
    );
}