import Ansi from "ansi-to-react";
import moment from "moment-timezone";
import React, { FunctionComponent } from "react";
import { Badge, Card, Table } from "react-bootstrap";
import { Controlled as CodeMirror } from "react-codemirror2";
import { ExecuteInput, ExecuteReply, ExecuteResult, Message, MessageStatus, Stream } from "../message";
import { SubmissionTransfer } from "../service";
import { ReviewToolbar } from "./reviewtoolbar";


interface SubmissionParams {
    submission: SubmissionTransfer,
    remove(id?: string): void;
    grade(submission: SubmissionTransfer, value: number): void;
}

interface MessageParams {
    snippet?: string,
    message?: Message,
}

interface MessagesParams {
    snippet?: string,
    messages?: Message[],
}

const ReplyStatus: FunctionComponent<MessageParams> = (params) => {
    const content = params.message?.content as ExecuteReply;

    switch (content.status) {
        case MessageStatus.ok:
            return <Badge bg="success" className="ms-2">成功</Badge>;
        case MessageStatus.error:
            return <Badge bg="danger" className="ms-2">出错</Badge>;
        case MessageStatus.aborted:
            return <Badge bg="info" className="ms-2">终止</Badge>;
        default:
            return <Badge bg="warning" className="ms-2">异常</Badge>;
    }
}

const ReplySection: FunctionComponent<MessageParams> = (params) => {
    const content = params.message?.content as ExecuteReply;

    switch (content?.status) {
        case MessageStatus.error:
            return (
                <pre>
                    <Ansi>
                        {content?.traceback.join("\n")}
                    </Ansi>
                </pre>
            );
        default:
            return (
                <>
                </>
            );
    }
}

const InputSection: FunctionComponent<MessageParams> = (params) => {
    const content = params.message?.content as ExecuteInput;
    const code = content?.code || "";

    return (
        <CodeMirror
            className="mt-4 mb-4"
            value={code}
            options={{
                mode: "python",
                theme: "eclipse",
                lineNumbers: true,
                lineWrapping: true,
                indentUnit: 4,
                autoRefresh: true,
                readOnly: true,
            }}
            onBeforeChange={(editor, data, python) => {
            }}
        />
    );
}

const ResultSection: FunctionComponent<MessageParams> = (params) => {
    const content = params.message?.content as ExecuteResult;

    return (
        <>
            {
                content &&
                <pre className="mt-4">
                    {
                        JSON.stringify(content?.data, null, 2)
                    }
                </pre>
            }
        </>
    );
}

const StreamSection: FunctionComponent<MessagesParams> = (params) => {
    const content = params.messages?.map(message =>
        JSON.stringify((message.content as Stream).text)
    ).reduce((accumulator, current) => accumulator + "\n" + current, "");

    return (
        <>
            {
                content &&
                <pre className="mt-4">
                    <Ansi>
                        {
                            content
                        }
                    </Ansi>
                </pre>
            }
        </>
    );
}

export const SubmissionItem: FunctionComponent<SubmissionParams> = (params) => {
    const message = params.submission.execute_input;
    const date = message?.header.date;
    const time = date ? moment(date.substring(0, date.indexOf("."))).format("YYYY-MM-DD HH:mm:ss") : "丢失";

    return (
        <Card className="mb-4">
            <Card.Body>
                <Table borderless size="sm">
                    <tbody>
                        <tr>
                            <td style={{ width: "15%" }}>用户</td>
                            <td style={{ width: "40%" }}>
                                {params.submission.username} @ {time}
                            </td>
                            <td style={{ width: "25%" }}>用户执行信息</td>
                            <td style={{ width: "20%" }}></td>
                        </tr>
                        <tr>
                            <td>执行</td>
                            <td>
                                {params.submission.snippet}
                                <ReplyStatus message={params.submission?.execute_reply} />
                            </td>
                            <td>代码执行的时间和结果</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>评审</td>
                            <td>
                                <ReviewToolbar
                                    submission={params.submission}
                                    remove={params.remove}
                                    grade={params.grade}
                                />
                            </td>
                            <td>通过命令对代码进行评审</td>
                            <td></td>
                        </tr>
                    </tbody>
                </Table>

                <InputSection
                    message={params.submission?.execute_input}
                    snippet={params.submission?.snippet}
                />

                <ReplySection
                    message={params.submission?.execute_reply}
                />

                <ResultSection
                    message={params.submission?.execute_result}
                />

                <StreamSection
                    messages={params.submission?.stream}
                    snippet={params.submission?.snippet}
                />
            </Card.Body>
        </Card>
    );
}