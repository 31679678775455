import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Badge, Button, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { EnrollmentTransfer, LessonTransfer, materialService, RecipeTransfer } from "../service";
import { SystemActionType, SystemNotifyAction } from "../store";
import { SubmissionList } from "./submissionlist";

interface Review {
    username: string;
    recipe: number;
}

interface RegistrationInfoParams {
    username: string;
    course?: string;
}

interface RecipeTableParams {
    lesson?: LessonTransfer;
    enrollments?: EnrollmentTransfer[];
}

const RegistrationInfo: FunctionComponent<RegistrationInfoParams> = ({ username, course }) => {
    const dispatch = useDispatch();

    const [registered, setRegistered] = useState(false);

    const check = useCallback(async () => {
        if (username && course) {
            const response = await materialService().get(`/registration/${username},${course}`);
            switch (response.status) {
                case 200:
                    setRegistered(true);
                    break;
                case 404:
                    setRegistered(false);
                    break;
                default:
                    dispatch<SystemNotifyAction>({
                        type: SystemActionType.NOTIFY,
                        toast: true,
                        title: "🛸",
                        subtitle: new Date().toLocaleTimeString(),
                        content: `错误信息：${response.status} ${response.statusText}，请联系管理员。`,
                    });
                    break;
            }
        }
    }, [username, course, dispatch]);

    useEffect(() => {
        check();
    }, [check]);

    return (
        <td>
            {
                registered ?
                    <Badge bg="success" className="ms-2">正常</Badge>
                    :
                    <Badge bg="danger" className="ms-2">错误</Badge>
            }
        </td>
    );
}

const RecipeTable: FunctionComponent<RecipeTableParams> = ({ lesson, enrollments }) => {
    const [review, setReview] = useState<Review>();

    const sections = lesson?.chapter?.sections || [];
    const recipes = ([] as RecipeTransfer[]).concat(sections, lesson?.prerequisites || [], lesson?.relatives || []);

    return (
        <>
            <Table bordered hover responsive size="sm" className="mt-4 mb-4">
                <thead>
                    <tr>
                        <th>用户</th>
                        <th>权限</th>
                        {
                            recipes.map(recipe =>
                                <th key={recipe.id}>
                                    {recipe.title}
                                </th>
                            )
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        enrollments?.map(enrollment =>
                            <tr key={enrollment.user}>
                                <td>
                                    {enrollment.user}
                                </td>
                                <RegistrationInfo username={enrollment.user} course={lesson?.chapter?.course?.alias} />
                                {
                                    recipes.map(recipe =>
                                        <td key={recipe.id}>
                                            <Button
                                                variant={review?.username === enrollment.user && review?.recipe === recipe.id ? "primary" : "link"}
                                                size="sm"
                                                style={{ textDecoration: "none" }}
                                                className="pt-0 pb-0"
                                                onClick={() => setReview({ username: enrollment.user, recipe: recipe.id })}
                                            >
                                                查看
                                            </Button>
                                        </td>
                                    )
                                }
                            </tr>
                        )
                    }
                </tbody>
            </Table>

            {
                review?.recipe && review?.username &&
                <SubmissionList recipe={review?.recipe} username={review?.username} />
            }
        </>
    );
}

export { RecipeTable };
