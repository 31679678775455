import React, { FormEvent, FunctionComponent } from "react";
import { ButtonToolbar, Form } from "react-bootstrap";
import { SubmissionTransfer } from "../service";
import { SubmissionRemove } from "./submissionremove";
import { SubmissionGrade } from "./submissiongrade";

interface Params {
    submission: SubmissionTransfer;
    grade: (submission: SubmissionTransfer, value: number) => void;
    remove: (id: string) => void;
}

export const ReviewToolbar: FunctionComponent<Params> = (params) => {
    const submit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
    }

    const reset = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
    };

    return (
        <ButtonToolbar>
            <Form onSubmit={submit} onReset={reset}>
                <SubmissionGrade
                    submission={params.submission}
                    grade={params.grade}
                />

                <span className="ms-2 me-2"></span>

                <SubmissionRemove
                    submission={params.submission}
                    remove={params.remove}
                />
            </Form>
        </ButtonToolbar>
    );
}