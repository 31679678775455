import React, { ChangeEvent, FormEvent, FunctionComponent, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { materialService, TutorTransfer } from "../service";
import { AppStore, SystemActionType, SystemNotifyAction } from "../store";

interface Params {
    show: boolean;
    tutor: TutorTransfer,
    onExit: () => void,
}

export const TutorInfo: FunctionComponent<Params> = (params) => {
    const passport = useSelector((store: AppStore) => store.passport);
    const dispatch = useDispatch();

    const [tutor, setTutor] = useState<Partial<TutorTransfer>>({
        fullname: params.tutor.fullname,
        english: params.tutor.english,
        title: params.tutor.title,
    });
    const [spin, setSpin] = useState(false);
    const [show, setShow] = useState<boolean>(params.show);
    const [validated, setValidated] = useState(false);

    useEffect(() => {
        setShow(params.show);
    }, [params.show]);

    const initialize = () => {
    };

    const finalize = () => {
        setValidated(false);

        params.onExit();
    }

    const change = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setTutor({
            ...tutor,
            [name]: value.trim(),
        });
    };

    const update = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (event.currentTarget.checkValidity()) {
            setSpin(true);

            const response = await materialService().put(`/tenant/${passport.tenant}/tutor/${params.tutor.username}`, tutor);

            setShow(false);
            setSpin(false);

            switch (response.status) {
                case 200:
                    break;
                default:
                    dispatch<SystemNotifyAction>({
                        type: SystemActionType.NOTIFY,
                        toast: true,
                        title: "🛸",
                        subtitle: new Date().toLocaleTimeString(),
                        content: `错误信息：${response.status} ${response.statusText}，请联系管理员。`,
                    });
                    break;
            }
        } else {
            setValidated(true);
        }
    };

    return (
        <Modal show={show} size="lg" onEnter={initialize} onExited={finalize}>
            <Modal.Header>
                <Modal.Title>信息</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validated} onSubmit={update} id="form">
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="fullname">
                            <Form.Label>称呼</Form.Label>
                            <Form.Control
                                type="text"
                                name="fullname"
                                value={tutor.fullname || ""}
                                required
                                pattern="[A-Za-z\u3400-\u4DBF\u4E00-\u9FFF_]+"
                                minLength={2}
                                onChange={change}
                            />
                            <Form.Control.Feedback type="invalid">
                                需要2个以上的中英文字母
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="english">
                            <Form.Label>英文</Form.Label>
                            <Form.Control
                                type="text"
                                name="english"
                                value={tutor.english || ""}
                                required
                                pattern="[a-zA-Z0-9_\s]+"
                                minLength={2}
                                onChange={change}
                            />
                            <Form.Control.Feedback type="invalid">
                                需要2个以上的英文大小写字母或者数字
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="title">
                            <Form.Label>抬头</Form.Label>
                            <Form.Control
                                type="text"
                                name="title"
                                value={tutor.title || ""}
                                required
                                pattern="[A-Za-z\u3400-\u4DBF\u4E00-\u9FFF_]+"
                                minLength={2}
                                onChange={change}
                            />
                            <Form.Control.Feedback type="invalid">
                                需要2个以上的中英文字母
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="primary" type="submit" form="form" disabled={spin}>
                    {
                        spin && <Spinner as="span" size="sm" animation="border" variant="light" className="me-2" />
                    }
                    确认
                </Button>

                <Button variant="outline-secondary" onClick={() => setShow(false)}>
                    取消
                </Button>
            </Modal.Footer>
        </Modal>
    );
}