import { Message } from "../message";

export * from "./service";

export enum CourseType {
    FREEMIUM = "FREEMIUM",
    PREMIUM = "PREMIUM",
}

export enum RecipeType {
    COURSE = "COURSE",
    BLOG = "BLOG",
    KNOWLEDGE = "KNOWLEDGE",
    SCHOLAR = "SCHOLAR",
    INTRO = "INTRO",
    REVIEW = "REVIEW",
    QUIZ = "QUIZ",
}

export enum DifficultyLevel {
    EASY = "easy",
    NORMAL = "normal",
    HARD = "hard"
}

export interface CourseTransfer {
    alias: string;
    title: string;
    type: CourseType;
    summary: string;
    intro: string;
    thumbnail: string;
    author: string;
}

export interface ChapterTransfer {
    id: number;
    title: string;
    summary: string;
    course?: CourseTransfer;
    sections: RecipeTransfer[];
}

export interface TutorTransfer {
    username: string;
    fullname?: string;
    portrait?: string;
    icon?: string;
    english?: string;
    intro?: string;
    title?: string;
    bio?: string
};

export interface TenantTransfer {
    id: number;
    code?: string;
    fullname?: string;
    created?: string;
    active?: boolean;
    headmaster?: string;
}

export interface CircleTransfer {
    id: number;
    name?: string;
    tenant?: string;
    comment?: string;
    tutor?: string;
    thumbnail?: string;
    lessons: LessonTransfer[];
    users: UserTransfer[];
}

export interface RecipeTransfer {
    id: number;
    title: string;
    summary: string;
    thumbnail: string;
    content?: string;
    chapter_id?: number;
    chapter_title?: string;
    course_id?: number;
    course_title?: string;
    next?: number;
    type?: RecipeType;
    prerequisites: RecipeTransfer[];
    relatives: RecipeTransfer[];
}

export interface LessonTransfer {
    id: number;
    name: string;
    time: string;
    zone: string;
    circle?: number;
    duration: number;
    webinar: string;
    record: string;
    summary: string;
    tutor: number;
    chapter?: ChapterTransfer;
    prerequisites: RecipeTransfer[];
    relatives: RecipeTransfer[];
}

export interface UserTransfer {
    id?: number;
    username: string;
    password?: string;
    fullname?: string;
    guardian?: string;
    mobile?: string;
    email?: string;
    channel?: string;
    dateCreated?: string;
    dateExpired?: string;
    active?: boolean;
    roles: Array<string>;
}

export interface EnrollmentTransfer {
    circle: number;
    user: string;
    certificate: string;
    alias: string;
}

export interface SubmissionTransfer {
    id: string;
    snippet: string;
    username: string;
    grade: number;
    recipe: number;
    execute_reply: Message;
    execute_input: Message;
    execute_result: Message;
    stream: Message[];
}

export interface CodeTransfer {
    id: number;
    code?: string;
    count: number;
    comment?: string;
    creator: string;
    expiration: string;
}

export interface RegistrationTransfer {
    course_id: number;
    course_title: string;
    username: string;
    time: string;
    operator: string;
}

export interface SettingTransfer {
    difficulty: DifficultyLevel;
}

export interface HealthTransfer {
    status: string;
}