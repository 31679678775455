import axios from "axios";
import moment from "moment";
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import { Button, Card, Table } from "react-bootstrap";
import { BiCopy } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { LessonTransfer, materialService } from "../service";
import { AppStore, SystemActionType, SystemNotifyAction } from "../store";
import { LessonEdit } from "./lessonedit";

interface Params {
    id: number,
}

const LessonBasic: FunctionComponent<Params> = ({ id }) => {
    const passport = useSelector((store: AppStore) => store.passport);
    const dispatch = useDispatch();

    const [lesson, setLesson] = useState<LessonTransfer>();
    const [edit, setEdit] = useState<boolean>(false);
    const abort = useRef<AbortController>();

    const getLesson = useCallback(async () => {
        try {
            abort.current = new AbortController();
            const response = await materialService().get(`/tenant/${passport.tenant}/lesson/${id}`, {
                signal: abort.current.signal,
            });

            switch (response.status) {
                case 200:
                    setLesson(response.data as LessonTransfer);
                    break;
                default:
                    dispatch<SystemNotifyAction>({
                        type: SystemActionType.NOTIFY,
                        toast: true,
                        title: "🛸",
                        subtitle: new Date().toLocaleTimeString(),
                        content: `错误信息：${response.status} ${response.statusText}，请联系管理员。`,
                    });
                    break;
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                // this should be fine
            } else {
                console.log("Error found in account service request", error);
            }
        }
    }, [passport.tenant, id, dispatch])

    useEffect(() => {
        getLesson();
    }, [getLesson]);

    useEffect(() => {
        return () => {
            abort.current?.abort();
        }
    }, []);

    return (
        <>
            <Card className="mb-4">
                <Card.Body>
                    <Table borderless size="sm">
                        <thead>
                            <tr>
                                <th style={{ width: "25%" }}>课程信息</th>
                                <th style={{ width: "25%" }}></th>
                                <th style={{ width: "25%" }}></th>
                                <th style={{ width: "25%", textAlign: "right" }}>
                                    <Button variant="outline" onClick={() => setEdit(true)}>
                                        <span role="img" aria-label="edit">✏️</span>
                                    </Button>

                                    {
                                        lesson &&
                                        <LessonEdit
                                            show={edit}
                                            id={id}
                                            lesson={lesson}
                                            onExit={() => { setEdit(false); getLesson(); }}
                                        />
                                    }
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>编号</td>
                                <td>
                                    {lesson?.id}
                                </td>
                                <td>标识课程的编号</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>机构</td>
                                <td>
                                    {passport.tenant}
                                </td>
                                <td>课程所属机构的代号</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>内容</td>
                                <td>
                                    {lesson?.chapter?.title}
                                </td>
                                <td>课程讲解的章节名称</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>时间</td>
                                <td>
                                    {moment(lesson?.time).local().format("YYYY/MM/DD HH:mm")}
                                </td>
                                <td>上课开始的时间</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>时长</td>
                                <td>
                                    {lesson?.duration}
                                </td>
                                <td>上课预计需要多少分钟</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>网课</td>
                                <td>
                                    {
                                        lesson?.webinar &&
                                        <a href={lesson?.webinar} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                                            网课链接
                                        </a>
                                    }
                                </td>
                                <td>网络会议的链接</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>录像</td>
                                <td>
                                    {
                                        lesson?.record &&
                                        <>
                                            网盘信息
                                            <Button variant="link" size="sm" onClick={() => navigator.clipboard.writeText(lesson.record)}>
                                                <BiCopy color={"grey"} />
                                            </Button>
                                        </>
                                    }
                                </td>
                                <td>课堂录像的下载信息</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </>
    );
}

export { LessonBasic };
