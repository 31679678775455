import axios from "axios";
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import { Button, Card, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { materialService, TutorTransfer } from "../service";
import { AppStore, SystemActionType, SystemNotifyAction } from "../store";
import { TutorBio } from "./tutorbio";
import { TutorInfo } from "./tutorinfo";
import { TutorIntro } from "./tutorintro";

interface RouteParams {
    username: string
}

const TutorExtra: FunctionComponent = () => {
    const passport = useSelector((store: AppStore) => store.passport);
    const { username } = useParams<RouteParams>();
    const dispatch = useDispatch();
    const abort = useRef<AbortController>();

    const [tutor, setTutor] = useState<TutorTransfer>();
    const [info, setInfo] = useState<boolean>(false);
    const [intro, setIntro] = useState<boolean>(false);
    const [bio, setBio] = useState<boolean>(false);

    const getTutor = useCallback(async () => {
        try {
            abort.current = new AbortController();
            const response = await materialService().get(`/tenant/${passport.tenant}/tutor/${username}`, {
                signal: abort.current.signal,
            });
            switch (response.status) {
                case 200:
                    setTutor(response.data as TutorTransfer);
                    break;
                default:
                    dispatch<SystemNotifyAction>({
                        type: SystemActionType.NOTIFY,
                        toast: true,
                        title: "🛸",
                        subtitle: new Date().toLocaleTimeString(),
                        content: `错误信息：${response.status} ${response.statusText}，请联系管理员。`,
                    });
                    break;
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                // this should be fine
            } else {
                console.log("Error found in account service request", error);
            }
        }
    }, [username, passport.tenant, dispatch]);

    useEffect(() => {
        getTutor();
    }, [getTutor]);

    useEffect(() => {
        return () => {
            abort.current?.abort();
        }
    }, []);

    return (
        <>
            <Card className="mb-4">
                <Card.Body>
                    <Table borderless size="sm">
                        <thead>
                            <tr>
                                <th style={{ width: "25%" }}>导师信息</th>
                                <th style={{ width: "25%" }}></th>
                                <th style={{ width: "25%" }}></th>
                                <th style={{ width: "25%", textAlign: "right" }}>
                                    <Button variant="outline" onClick={() => setInfo(true)}>
                                        <span role="img" aria-label="edit">✏️</span>
                                    </Button>
                                    {
                                        tutor &&
                                        <TutorInfo show={info} tutor={tutor} onExit={() => { setInfo(false); getTutor(); }} />
                                    }
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>称呼</td>
                                <td>
                                    {tutor?.fullname || "无"}
                                </td>
                                <td>同学如何称呼导师</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>全名</td>
                                <td>
                                    {tutor?.english || "无"}
                                </td>
                                <td>导师的英文名字</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>抬头</td>
                                <td>
                                    {tutor?.title || "无"}
                                </td>
                                <td>导师的职称抬头</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>

            <Card className="mb-4">
                <Card.Body>
                    <Table borderless size="sm">
                        <thead>
                            <tr>
                                <th style={{ width: "90%" }}>自我介绍</th>
                                <th style={{ width: "10%", textAlign: "right" }}>
                                    <Button variant="outline" onClick={() => setIntro(true)}>
                                        <span role="img" aria-label="edit">✏️</span>
                                    </Button>
                                    {
                                        tutor &&
                                        <TutorIntro show={intro} tutor={tutor} onExit={() => { setIntro(false); getTutor(); }} />
                                    }
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    {tutor?.intro || "无"}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>

            <Card className="mb-4">
                <Card.Body>
                    <Table borderless size="sm">
                        <thead>
                            <tr>
                                <th style={{ width: "90%" }}>个人简历</th>
                                <th style={{ width: "10%", textAlign: "right" }}>
                                    <Button variant="outline" onClick={() => setBio(true)}>
                                        <span role="img" aria-label="edit">✏️</span>
                                    </Button>
                                    {
                                        tutor &&
                                        <TutorBio show={bio} tutor={tutor} onExit={() => { setBio(false); getTutor(); }} />
                                    }
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    {tutor?.bio || "无"}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </>
    );
}

export { TutorExtra };
