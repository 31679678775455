import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { notebookService, SubmissionTransfer } from "../service";
import { SystemActionType, SystemNotifyAction } from "../store";
import { SubmissionItem } from "./submissionitem";
import { SubmissionStat } from "./submissionstat";

interface Params {
    recipe: number;
    username: string;
}

export const SubmissionList: FunctionComponent<Params> = (props) => {
    const dispatch = useDispatch();

    const [submissions, setSubmissions] = useState<SubmissionTransfer[]>();

    const get = useCallback(async () => {
        console.log("Get submission for recipe", props.recipe, "by", props.username);

        const response = await notebookService().get(`/submission`, {
            params: {
                username: props.username,
                recipe: props.recipe,
            }
        });

        switch (response.status) {
            case 200:
                const qualified = (response.data as SubmissionTransfer[]).filter(sub => sub.execute_reply ? true : false);
                setSubmissions(qualified);
                break;
            default:
                dispatch<SystemNotifyAction>({
                    type: SystemActionType.NOTIFY,
                    toast: true,
                    title: "🛸",
                    subtitle: new Date().toLocaleTimeString(),
                    content: `错误信息：${response.status} ${response.statusText}，请联系管理员。`,
                });
                break;
        }
    }, [props.username, props.recipe, dispatch]);

    const remove = async (id?: string) => {
        console.log("Delete submission with id", id);

        const response = await notebookService().delete(`/submission/${id}`);

        switch (response.status) {
            case 200:
                const remain = submissions?.filter(sub => sub.id !== id);
                setSubmissions(remain);

                break;
            default:
                dispatch<SystemNotifyAction>({
                    type: SystemActionType.NOTIFY,
                    toast: true,
                    title: "🛸",
                    subtitle: new Date().toLocaleTimeString(),
                    content: `错误信息：${response.status} ${response.statusText}，请联系管理员。`,
                });
                break;
        }
    };

    const grade = async (submission: SubmissionTransfer, value: number) => {
        const updated = { ...submission, grade: value } as SubmissionTransfer;

        const response = await notebookService().patch(`/submission/${submission.id}`, updated);
        switch (response.status) {
            case 200:
                setSubmissions(submissions?.map(sub =>
                    sub.id === submission.id ?
                        updated
                        :
                        sub
                ));
                break;
            default:
                console.warn("Failed to update grade");
                break;
        }
    };

    useEffect(() => {
        get();
    }, [get]);

    return (
        <>
            <h3 className="mt-5">
                数据分析
            </h3>

            <SubmissionStat submissions={submissions || []} />

            <h3 className="mt-5">
                评审代码
            </h3>

            {
                submissions?.filter(submission => submission.grade > 0)
                    .map(submission =>
                        <SubmissionItem
                            key={submission.id}
                            submission={submission}
                            grade={grade}
                            remove={remove}
                        />
                    )
            }

            <h3 className="mt-5">
                全部代码
            </h3>
            {
                submissions?.map(submission =>
                    <SubmissionItem
                        key={submission.id}
                        submission={submission}
                        grade={grade}
                        remove={remove}
                    />
                )
            }
        </>
    );
}